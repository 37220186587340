/* Global styles */
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

ul {
  list-style-type: none;
}

.fa-search {
  color: white;
}

.remove-anchor-style {
  text-decoration: none;
  color: black;
}

.rice-notif {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 1em;
  margin-bottom: 1em;
  width: 70%;
  padding: 1em 2em;
  border-radius: 15px;
  color: rgb(109, 15, 15);
  background-color: rgb(223, 134, 134);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

@media only screen and (min-width: 200px)  {  
  .container {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }

  .notification-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }

  .notif  {
    width: 100%;
    margin: 1em;
    padding: 1em 1em;
    color: rgb(86, 86, 86);
    font-weight: 900;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background: linear-gradient(0deg, rgba(98,164,113,0.05) 0%, rgba(255,255,255,1) 100%);
    border-radius: 15px;
  }

  .banner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50%;
    background-size: cover;
    background-image: url("../public/img/banner.jpg");
  }

  .title-box {
    margin-top: 1.3em;
    margin-bottom: 3em;
    margin-right: 1.4em;
    padding: 0.1em;
    width: 50vw;
    align-self: flex-end;
    text-align: center;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .banner .title {
    margin: 0 0 0.1em 0;
    font-family: 'Playfair Display', serif;
    font-size: 25px;
    font-weight: 800;
  }
  
  .banner h2 {
    font-size: medium;
  }

  .banner .telephone {
    font-size: small;
    font-weight: bolder;
  }
  
  .search-box{
    display: block;
    align-self: center;
  }
  
  .search-input {
    padding: 1em;
    border: none;
    border-radius: 5px;
    font-size: medium;
    font-weight: 500;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  
  .search-btn {
    float: right;
    padding: 1em;
    border: none;
    border-radius: 5px;
    background: #0f6325;
    font-size: 17px;
  }

  /* mobile accordian */
  .category-nav ul {
    padding-left: 0;
  }

  .category-nav li {
    display: flex;
    padding: 1em 0;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    font-family: 'Playfair Display', serif;
    font-size: larger;
  }

  .svg-arrow {
    align-self: flex-end;
    width: 25px;
    height: 25px;
    stroke: grey;
    color: grey;
  }

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .menu-item-name {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .menu-item-name .index{
    margin-right: 0.1em;
  }
  
  .menu-item-name div h3 {
    margin: 0.2em;
  }

  .menu-item-name .alpha-index{
    display: flex;
    margin: 0.1em 0.1em 0 0;
    padding: 0.4em;
    height: 0.8em;
    align-items: center; 
    border-radius: 15px;
    background-color: rgba(255,255,255,0.5);
  }

  .menu-item-name .set-menu-item span{
    margin-left: 0.1em;
    font-style: italic;
    font-size: small;
    font-weight: 800;
  }


  .menu-item-name .inline {
    margin-left: 0.1em;
    font-size: small;
    font-style: italic;
  }

  .menu-item-name .description {
    margin: 0.1em auto;
    font-weight: bolder;
  }

  .menu-item .price {
    margin-left: 0.5em;
  }

  .map {
    margin: 2em auto;
  }
  
  iframe {
    width: 90vw;
    height: 50vh;
    border: 0;
  }
}

@media only screen and (min-width: 700px)  {
  .underline {
    border: 3px solid #a01300;
  }

  .title-box {
    grid-area: title;
    padding: 2em;
    margin-top: 1em;
    margin-left: 15rem;
    margin-right: 2em;
    width: 15rem;
    max-height: 15rem;
    text-align: center;
    border: 3px solid black;
    background-color: transparent;
  }

  .banner {
    height: 65vh;
    background-size: cover;
    background-image: url("../public/img/banner.jpg");
  }

  .banner .title {
    margin: 0 0 0.3em 0;
    font-family: 'Playfair Display', serif;
    font-size: 30px;
    font-weight: 600;
  }
  
  .banner .telephone {
    padding: 0.2em 0;
    background-color: rgba(145, 137, 137, 0.5);
  }
}

@media only screen and (min-width: 1024px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-auto-rows: minmax(auto, auto);
    grid-template-areas: 
        "category-nav results"
        "map map"
        "footer footer";
    padding: 1em;
  }

  .notification-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 
      "notif1 notif2 notif3 notif4"
      "notif5 notif5 notif5 notif5"
      " . notif6 notif6 . ";
    margin: 1em;
    justify-content: space-around;
  }
  
  .notif {
    padding: 1.2em 1em;
    width: 70%;
    color: rgb(86, 86, 86);
    font-weight: 900;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background: linear-gradient(0deg, rgba(98,164,113,0.05) 0%, rgba(255,255,255,1) 100%);
    border-radius: 15px;
  }

  .notif1{
    grid-area: notif1;
  }

  .notif2{
    grid-area: notif2;
  }

  .notif3{
    grid-area: notif3;
  }

  .notif4{
    grid-area: notif4;
    justify-self: center;
  }

  .notif5{
    grid-area: notif5;
    justify-self: center;
  }
  
  .rice-notif {
    grid-area: notif6;
    width: 65vw;
  }

  .underline {
    border: 3px solid #a01300;
  }
  
  .banner {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 2fr 1fr;
    grid-template-areas: 
        ". title"
        "search search";
    width: 100%;
    height: 20%;
    background-size: cover;
    background-image: url("../public/img/banner.jpg");
  }
  
  .title-box {
    grid-area: title;
    justify-self: end;
    padding: 2em;
    margin-top: 1em;
    margin-right: 2em;
    width: 25rem;
    max-height: 15rem;
    text-align: center;
    border: 3px solid black;
  }
  
  .banner .title {
    margin: 0 0 0.3em 0;
    font-family: 'Playfair Display', serif;
    font-size: 60px;
    font-weight: 400;
  }
  
  .banner .telephone {
    padding: 0.2em 0;
    background-color: rgba(145, 137, 137, 0.5);
  }
  
  .search-box{
    display: block;
    grid-area: search;
    margin: 2rem auto auto auto;
    align-self: center;
    width: 35rem;
  }
  
  .search-input {
    width: 85%;
    padding: 1em;
    border: none;
    border-radius: 5px;
    font-size: medium;
    font-weight: 500;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  
  .search-btn {
    float: right;
    padding: 1em;
    border: none;
    border-radius: 5px;
    background: #0f6325;
    font-size: 17px;
  }
  
  .category-nav {
    grid-area: category-nav;
  }
  
  .category-nav ul {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  
  .category-nav li {
    padding: 1em;
    border-bottom: 0;
    transition: 0.3s;
  }
  
  .category-nav li:hover{
    border-radius: 0px;
    margin-right: 0.5em;
    border-bottom: #a01300 solid 3px;
  }
  
  .results-box {
    grid-area: results;
    align-self: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: minmax(auto, 7em);
    margin-right: 2em;
    margin-bottom: 0.5em;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(204, 219, 232, 0.1);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }

  .set-meal-results {
    border-radius: 5px;
    background-color: rgba(204, 219, 232, 0.1);
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.5em 1em 0.5em 0.5em;
    margin: 2em;
    height: 25vh;
    border-radius: 5px;
    background-color: white;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }

  .card-set-meal {
    margin: 2em;
    border-radius: 5px;
    padding: 0.5em 1em 1em 0.5em;
    height: fit-content;
  }

  .card .row_1 {
    display: flex;
    flex-direction: row;
  }

  .card .row_1 .card-index:before {
    content:'';
    float: left;
    width: auto;
    padding-bottom: 100%;
  }

  .card .row_1 .card-index{
    display: inline-flex;
    margin: 0.5em 0.9em 0 0 ;
    padding: 1em;
    width: 1em;
    height: 1em;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    background-color: #0f6325;
    color: white;
    vertical-align: middle;
  }

  .card .row_1 .card-name{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-left: 0;
    font-size: large;
    font-weight: 800;
  }

  .card-set-meal .row_2{
    height: fit-content;
    margin-left: 1em;
    font-size: small;
    line-height: 0.5em;
  }

  .card .price {
    display: flex;
    margin-top: 0.5em;
    margin-left: 0.5em;
    vertical-align: baseline;
    font-weight: bolder;
  }

  .card .row_1 .alpha-index {
    display: inline-flex;
    margin: 0.5em 0.9em 0 0 ;
    padding: 1em;
    width: 1em;
    height: 1em;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    background-color: rgba(109, 109, 109, 0.5);
    color: white;
    vertical-align: middle;
  }
  
  .map {
    grid-area: map;
    height: fit-content;
    margin: 2em auto;
  }
  
  iframe {
    width: 93vw;
    border: 0;
  }

  .footer {
    grid-area: footer;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1em 2em;
  }
}